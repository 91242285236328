import { TermsProps } from '../../@interface/terms'

export const dataTermsPaidPlans: TermsProps = {
  title: 'TERMOS E CONDIÇÕES PLANO CORA PRO',
  subtitle: 'Plano Cora Pro',
  update: '18 de Agosto de 2023',
  about: {
    title: 'Termos de Uso',
    introduction:
      'Este documento (“Termos de Uso”) apresenta as condições de uso dos serviços relacionados ao Plano Pago da Conta Cora (“Serviços”) prestados pela CORA TECNOLOGIA LTDA, sociedade com sede na Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, no Município de São Paulo, Estado de São Paulo, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o no 34.052.649/0001-78 (“CORA TECH”), que desenvolve atividades de tecnologia e integra o mesmo grupo econômico que a Cora Sociedade de Crédito, Financiamento e Investimento S.A., sociedade com sede na Rua Frei Caneca, 1246, 3º andar, sala 31, Consolação, no Município de São Paulo, Estado de São Paulo, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o nº 37.880.206/0001-63 (“CORA SCFI” e quando usado em conjunto com a CORA TECH apenas “CORA” ou “Nós”), na qual Você mantém sua conta de pagamentos (“Conta Cora”).<br/><br/>Ao utilizar-se dos Serviços aqui descritos e oferecidos pela CORA TECH, você celebra um contrato conosco e assume os direitos e obrigações que apresentamos ao longo destes Termos de Uso.<br/><br/>Você deve ler atentamente estes Termos de Uso, por inteiro, pois a utilização dos Serviços implica sua total concordância com as disposições deste documento. Caso não concorde com qualquer disposição deste instrumento, não utilize os serviços.',
  },
  section: [
    {
      list: [
        {
          head: 'Definições Importantes',
          items: [
            {
              item:
                'O presente Termo e os direitos e obrigações aqui contidos são complementares aos Termos e Condições Gerais de Uso ("Termos de Uso") e a Política de Privacidade, que se aplicam a todos os titulares de Conta Cora e devem ser interpretados em conjunto.',
            },
            {
              item:
                'As definições aqui apresentadas foram retiradas dos Termos de Uso da Conta Cora e servem para o melhor entendimento dos termos utilizados para o Plano Pago da Conta Cora, quaisquer termos em letras maiúsculas que não sejam definidos aqui terão suas definições estabelecidas em nossos Termos de Uso.',
            },
            {
              item:
                'Nestes Termos de Uso, os termos abaixo iniciados em letras maiúsculas deverão ter o seguinte significado:<br/><br/>Conta CORA: é a conta de pagamento digital pré-paga destinada à realização de transações de pagamento.<br/><br/>Sua Empresa: pessoa jurídica constituída no Brasil, devidamente inscrita na Receita Federal do Brasil, titular da Conta CORA, cujos representantes são autorizados a acessar as Aplicações e/ou utilizar os Serviços.<br/><br/>Usuário: pessoa física representante de Sua Empresa, com poderes atribuídos pelos documentos societários de Sua Empresa, procuração.<br/><br/>Você: Usuário responsável pelo cadastro de Sua Empresa para acesso aos Serviços.',
            },
          ],
        },
      ],
    },
    {
      title: 'Serviços',
      list: [
        {
          head: 'Serviços',
          items: [
            {
              item:
                'Os Serviços oferecidos no Plano Pago da Conta Cora consistem na combinação dos seguintes Serviços: <b>i)</b> Customização do Boleto com a Logomarca da Sua Empresa; <b>ii)</b> Régua de Cobrança por SMS e/ou WhatsApp; <b>iii)</b> Gráficos de Gestão de Fluxo de Caixa; <b>iv)</b> Integração Direta; <b>v)</b> Emissão de Nota Fiscal Integrada.',
            },
          ],
        },
        {
          head: 'Customização do Boleto com a Logomarca da Sua Empresa',
          items: [
            {
              item:
                'Com a Customização do Boleto com o Logo da Sua Empresa, Você poderá inserir a logomarca da sua empresa nos boletos de cobrança que Você emitir pela Cora aos seus clientes.',
              child: [
                {
                  item:
                    'O envio da logomarca é feito através da plataforma web e poderá ser alterada/atualizada a cada 30 (trinta) dias.',
                },
                {
                  item:
                    'Após o envio da logomarca de Sua Empresa, todas as modalidades de boletos que Você emitir, serão emitidos com a logo, independente se forem emitidos pelo Aplicativo Cora, CoraWeb, API ou plataformas de terceiros.',
                },
                {
                  item:
                    'Os boletos que foram emitidos anteriormente ao envio da logomarca de Sua Empresa não serão atualizados.',
                },
              ],
            },
            {
              item:
                'Ao cancelar o Plano Pago da Cora, todos os boletos que já foram emitidos continuarão com a logomarca e os novos boletos emitidos após o fim da vigência do Plano Pago serão emitidos sem a logomarca.',
            },
            {
              item:
                'O Serviço de Customização do Boleto com a Logomarca da Sua Empresa poderá ser suspenso ou restringido a qualquer momento caso seja constatado uso que viole direitos de propriedade intelectual de terceiros, fomente propaganda enganosa ou gere confusão entre os consumidores finais.',
            },
            {
              item:
                'A Cora está isenta de qualquer responsabilidade, direta ou indireta, pelo uso de má-fé ou inadequado da Customização do Boleto por Você que ocasione violação de direitos de propriedade intelectual e de marca de terceiros.',
            },
          ],
        },
        {
          head: 'Régua de Cobrança por mensagem SMS e/ou WhatsApp',
          items: [
            {
              item:
                'Ao emitir um boleto de cobrança ao seu cliente, Você poderá configurar e customizar notificações, a serem enviadas via SMS, E-mail e/ou WhatsApp para o pagador do boleto. Sendo que a notificação por E-mail faz parte das funcionalidades inerentes à sua Conta Cora.',
              child: [
                {
                  item:
                    'A configuração da notificação poderá ser escolhida por Você, ou seja, a notificação de cobrança do boleto poderá ocorrer 2 (dois) dias antes do vencimento ou no dia do vencimento, entre outras opções disponibilizadas no Aplicativo Cora.',
                },
              ],
            },
            {
              item:
                'Feita a configuração por Você, a Cora enviará notificação de cobrança para o seu cliente nas datas escolhidas caso o boleto não tenha sido pago. Para os casos em que o boleto já tenha sido pago, a comunicação não será enviada.',
            },
            {
              item:
                'A configuração da notificação de cobrança apenas será disponibilizada e acontecerá após a contratação do Plano Pago, ou seja, será aplicável para novos boletos que Você emitir após a contratação do Plano Pago. Não será possível configurar notificações via SMS para boletos já emitidos.',
            },
            {
              item: `Caso você cancele seu Plano Pago da Cora e tenha boletos emitidos com notificações de cobrança já configuradas, elas não serão enviadas.`,
            },
            {
              item: `A Cora não se responsabiliza pela entrega dos SMS em casos de indisponibilidade da operadora, roaming, celular fora de área ou número de telefone errado, entre outras hipóteses. O Serviço aqui apresentado não engloba a possibilidade de confirmar se o destinatário recebeu e/ou leu o SMS.`,
            },
            {
              item:
                'A Cora não se responsabiliza pela entrega dos E-mails e/ou mensagem de WhatsApp de cobrança caso você não tenha fornecido as informações de contato incompletas, desatualizadas ou incorretas. O Serviço aqui apresentado não engloba a possibilidade de confirmar se o destinatário recebeu a notificação de cobrança.',
            },
            {
              item:
                'O envio de notificação de cobrança pelo contato de WhatsApp terá o valor de R$0,49 (quarenta e nove centavos) por notificação, a ser cobrado a parte da contração do Plano Pago.',
            },
            {
              item:
                'Você se responsabiliza por coletar a concordância do seu cliente para que a notificação de cobrança seja enviada a ele por meio do seu WhatsApp.',
            },
            {
              item: `Você se responsabiliza por qualquer forma, abordagem e frequência em que a notificação de cobrança venha a ocasionar eventual cunho vexatório ou cobrança indevida.`,
            },
          ],
        },
        {
          head: ' Gráficos de Gestão de Fluxo de Caixa',
          items: [
            {
              item:
                'Você terá acesso a um gráfico com filtros de data e o valor contendo a soma de todos os valores de entrada e todos os valores de saída de dinheiro da sua Conta Cora, de acordo com o uso do Aplicativo Cora para as transações da Sua Empresa, agrupados por períodos diferentes: dia, semana, mês e futuro.',
              child: [
                {
                  item:
                    'A soma dos valores de entrada irá considerar valores que entraram na conta independente do método de pagamento (via depósito, boleto, Pix, TED ou instituição domicílio).',
                },
                {
                  item:
                    'A soma dos valores de saída irá considerar valores que saíram através dos métodos: pagamento de boleto ou impostos, TED, Pix, cartão de débito, pagamento de fatura do cartão de crédito (se houver) via débito em conta ou boleto bancário.',
                },
              ],
            },
            {
              item:
                'O período "Futuro" levará em consideração pagamentos, transferências agendadas e boletos de cobrança ou depósito em aberto. A atualização dos valores exibidos é realizada em até 1 (uma) hora assim que as movimentações são confirmadas.',
            },
            {
              item:
                'A exibição dos valores e gráficos será feita de forma agrupada e de forma categorizada por métodos de pagamento, a depender da sua escolha.',
            },
            {
              item:
                'A Cora não se responsabiliza por inconsistências de informações apresentadas nos gráficos em decorrência da inserção de informações não acuradas por Você ou qualquer outro perfil com acesso à Conta Cora.',
            },
            {
              item:
                'A Cora não se responsabiliza por qualquer tomada de decisão empresarial da Sua Empresa que seja feita com base nos Gráficos de Gestão de Fluxo de Caixa disponibilizados.',
            },
          ],
        },
        {
          head: 'Integração Direta',
          items: [
            {
              item:
                'O Serviço de Integração Direta aqui descrito deve ser interpretado em conjunto com os nossos Termos de Serviço de API, que contém as regras e condições do uso de API’s na Cora.',
            },
            {
              item:
                'A(s) API(s) possibilita(m) a integração segura para, a depender do seu tipo, se Direta ou Indireta: (i) promover a facilitação no processo de abertura da Conta Cora de novos clientes; (ii) autorizar e autenticar contas de terceiros (clientes) para um determinado escopo; (iii) integrar a gestão de contas de terceiros (clientes); (iv) possibilitar a emissão e gestão de boletos; (v) possibilitar a iniciação de pagamentos e transferências;  e (vi) consulta de extratos bancários',
            },
            {
              item:
                'As API(s) disponíveis podem ser consultadas na documentação de API’s constantes nos <a href="https://www.cora.com.br/termos-e-condicoes-de-apis/#termo" target="_blank">Termos de Serviço de API da Cora</a> e no <a href="https://www.cora.com.br/" target="_blank">site da Cora</a>. no Aplicativo da Conta Cora ou na CoraWeb.',
            },
            {
              item:
                'As despesas necessárias à execução da(s) API(s) e eventuais adaptações na plataforma da Empresa para compatibilização da(s) API(s) e efetiva integração serão de responsabilidade da Empresa.',
            },
            {
              item: `Para a utilização das API(s) aqui descritas serão cobradas tarifas e taxas por produto e/ou serviço usado pela integração, conforme segue:
              <div class="table-paid-plans">
                <table>
                  <tbody>
                    <tr>
                      <td>Transferência</td>
                      <td>
                        TED<br/>
                        (transferência para outro banco)
                      </td>
                      <td>
                        R$ 2,00 por TED realizada
                      </td>
                    </tr>
                    <tr>
                      <td>Boleto</td>
                      <td>
                        Compensação de boletos por código de barras
                      </td>
                      <td>
                        R$ 1,70 por boleto compensado
                      </td>
                    </tr>
                    <tr>
                      <td> </td>
                      <td>Compensação de boletos por QR Code PIX (vide Cláusula 6.6)</td>
                      <td>1% do valor do boleto com um valor máximo de até R$ 0,50 por boleto compensado</td>
                    </tr>
                    <tr>
                      <td> </td>
                      <td>Cancelamento de boleto gerado via API (vide Cláusula 6.7)</td>
                      <td>R$ 0,30 por boleto cancelado</td>
                    </tr>
                  </tbody>
                </table>
              </div>`,
            },
            {
              item:
                'A tarifa equivalente a 1% é válida somente para boletos compensados no valor de até R$49,99. Boletos a partir de R$50,00 cobram o valor máximo de R$0,50 por boleto compensado.',
            },
            {
              item:
                'A taxa de cancelamento é aplicada exclusivamente para o cancelamento de boletos gerados por meio da nossa API. Essa taxa não é cobrada quando os boletos cancelados foram gerados através do nosso aplicativo ou da plataforma Cora Web.',
            },
            {
              item:
                '<b>A CORA NÃO ESTÁ OBRIGADA A FORNECER NENHUM SERVIÇO DE SUPORTE PARA EXECUÇÃO DA(S) API(S).</b>',
            },
          ],
        },
        {
          head: 'Emissão de Nota Fiscal Integrada',
          items: [
            {
              item:
                'Ao emitir uma cobrança pelo Aplicativo Cora ou pela CoraWeb, seja ela por boleto de cobrança simples ou por boleto com QR-code pix, Você poderá selecionar a opção de emitir uma nota fiscal (NFSe) do serviço prestado diretamente na plataforma da Cora.',
            },
            {
              item: `Ao selecionar a opção de emitir uma nota fiscal, Você precisará passar por algumas etapas de configuração do serviço: (i) Primeiramente, será preciso enviar o certificado A1 de sua empresa, para que a Cora possa indicar à prefeitura que sua empresa é a responsável pelo registro da Nota; (ii) Após o envio do certificado, você precisará preencher algumas informações que vão compor a Nota, tais como: CNAE; códigos de serviço; alíquota do INSS, descrição do serviço, dentre outras informações necessárias para o correto preenchimento do documento.`,
            },
            {
              item:
                'Você terá a opção de emitir a NFSe no momento da emissão do boleto de cobrança ou quando receber o pagamento do serviço prestado. Independente da opção escolhida, logo após a emissão da Nota Fiscal, a Cora enviará um e-mail ao seu endereço eletrônico cadastrado com o arquivo em formato PDF da Nota Fiscal.',
            },
            {
              item:
                'A disponibilidade do serviço de Emissão de Nota Fiscal depende da localização de Sua Empresa, portanto, para verificar se o serviço está disponível na sua região, Você poderá consultar pelo seguinte link ou no Aplicativo Cora.',
              child: [
                {
                  item:
                    'A eventual indisponibilidade da Nota Fiscal não acarretará qualquer desconto ou diminuição no valor do serviço Plano Pago da Cora.',
                },
              ],
            },
            {
              item:
                'Além da emissão da Nota Fiscal, será possível consultar as notas fiscais já emitidas e cancelá-las de acordo com a necessidade, diretamente pelo Aplicativo Cora ou CoraWeb.',
            },
            {
              item:
                'O custo de emissão por Nota Fiscal de Serviço será de R$ 0,49 (quarenta e nove centavos) dentro do Plano Pago da Cora e este valor será descontado diretamente da Sua Conta Cora quando da emissão da Nota Fiscal.',
            },
          ],
        },
        {
          head: 'Condições Gerais dos Planos Pagos',
          items: [
            {
              item:
                'O Plano Pago da Cora é um serviço por assinatura mensal no valor de R$ 44,90 (quarenta e quatro reais e noventa centavos), ou seja, a cobrança acontecerá por meio de débito em sua Conta Cora com recorrência mensal, podendo ser cancelada a qualquer momento.',
            },
            {
              item:
                'O Plano Pago da Cora poderá apenas ser contratado pelos sócios da Sua Empresa, ou seja, qualquer Usuário que possua perfil de sócio na Conta Cora.',
            },
            {
              item:
                'O cancelamento do Plano Pago poderá ser feito pelo Aplicativo Cora ou pela CoraWeb, sendo que para que a cobrança do mês subsequente não seja realizada, o cancelamento deve ser indicado antes da data de cobrança, independentemente em qual data do mês esta ocorrer, e poderá ser solicitado por qualquer Usuário que possua perfil de sócio na Conta Cora.',
            },
            {
              item:
                'Caso no momento da realização do débito em conta, o saldo disponível não for suficiente para o pagamento do serviço Plano Pago, serão feitas outras três tentativas nos três dias subsequentes à cobrança inicial, sendo que, após o terceiro dia de inadimplemento, o serviço será cancelado pela Cora.',
            },
            {
              item:
                'Você se compromete a não utilizar os serviços do Plano Pago da Cora para a realização de pagamentos, transferências ou cobranças relacionadas: (I) a atividades ilegais, tais como, lavagem de dinheiro, estelionato, realização de fraudes contra terceiros, jogos de azar e apostas em geral; (II) à compra e venda de produtos e serviços considerados ilegais pela legislação brasileira; (III) à venda de produtos ou serviços identificados pelos órgãos governamentais como tendo uma alta probabilidade de serem fraudulentos ou que representem risco à segurança física e financeira do consumidor; (IV) a produtos que incentivem, promovam, facilitem ou ensinem pessoas a praticarem atividades ilegais; e (V) à promoção de ódio, violência, racismo ou outras formas de intolerância discriminatória.',
            },
          ],
        },
        {
          head: 'Outras Informações',
          items: [
            {
              item: `A obrigação de confidencialidade aqui prevista permanecerá vigente enquanto perdurar o caráter de confidencialidade das informações recebidas`,
            },
            {
              item: `As Partes se comprometem a seguir regras de privacidade, proteção de Dados, confidencialidade ou requisitos de segurança de informações, em conformidade com as melhores práticas e a legislação aplicável, com o objetivo de garantir a confidencialidade e o uso adequado dos Dados, bem como a sua não divulgação, exceto na forma autorizada pelos Termos de Uso, pela presente Política de Privacidade ou pela legislação pertinente.`,
            },
            {
              item: `Em nenhuma circunstância a CORA se responsabilizará por qualquer delito, negligência, descumprimento contratual ou outra hipótese de ilícito civil ou penal que venham a ser causados por Você, incluindo o uso não autorizado de seus Dados, sendo sua total responsabilidade arcar com todos e quaisquer danos, monetários ou de outra natureza, decorrentes de sua atuação indireta, direta ou incidental, cabendo à CORA o direito de regresso por tais situações.`,
            },
            {
              item: `A CORA preza muito pela confiança depositada por Você e busca sempre manter medidas de segurança técnicas, físicas e administrativas para fornecer proteção razoável para os Dados Pessoais contra perda, mau uso, acesso não autorizado, divulgação e alteração (como firewalls, controles de acesso e criptografia de Dados). Ainda que a CORA mantenha essas medidas visando proteger os Serviços e Aplicações, você deve manter em segurança sua senha e demais informações sobre a Conta CORA de Sua Empresa, bem como se utilizar apenas de ambiente e equipamentos seguros para realizar as conexões necessárias.`,
            },
            {
              item: `Dependendo do tipo de Serviço que você, na qualidade de representante de Sua Empresa, contratar, é possível que esse Serviço seja prestado diretamente por terceiros. Nesse caso, você será redirecionado a uma página externa e deixará o ambiente CORA, devendo acessar, ler e aceitar os Termos e Condições e a Política de Privacidade desse terceiro. A CORA não possui qualquer ingerência no estabelecimento desses termos e políticas. Caso você decida utilizar os serviços e aplicações de terceiros, você o fará por sua conta e risco.`,
            },
            {
              item: `Essa Política de Privacidade é regida pelas Leis da República Federativa do Brasil.`,
            },
          ],
        },
        {
          head: 'Mudanças na Política de Privacidade',
          items: [
            {
              item: `Como estamos sempre buscando melhorar nossos serviços, a CORA poderá ajustar esta Política de Privacidade periodicamente. Desta forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento sobre as modificações. Caso sejam feitas alterações relevantes que ensejem em um novo consentimento seu, nós iremos te notificar com antecedência, de forma que, caso não concorde com os novos termos, você poderá optar por interromper a utilização dos Serviços e das Aplicações.`,
            },
            {
              item: `Última Atualização em 09/09/2022`,
            },
          ],
        },
      ],
    },
    {
      title: 'Condições Gerais dos Planos Pagos',
      list: [
        {
          head: 'Condições Gerais dos Planos Pagos',
          items: [
            {
              item:
                'O Plano Pago da Cora é um serviço por assinatura mensal no valor de R$ 44,90 (quarenta e quatro reais e noventa centavos), ou seja, a cobrança acontecerá por meio de débito em sua Conta Cora com recorrência mensal, podendo ser cancelada a qualquer momento.',
            },
            {
              item:
                'O Plano Pago da Cora poderá apenas ser contratado pelos sócios da Sua Empresa, ou seja, qualquer Usuário que possua perfil de sócio na Conta Cora.',
            },
            {
              item:
                'O cancelamento do Plano Pago poderá ser feito pelo Aplicativo Cora ou pela CoraWeb, sendo que para que a cobrança do mês subsequente não seja realizada, o cancelamento deve ser indicado antes da data de cobrança, independentemente em qual data do mês esta ocorrer, e poderá ser solicitado por qualquer Usuário que possua perfil de sócio na Conta Cora.',
            },
            {
              item:
                'Caso no momento da realização do débito em conta, o saldo disponível não for suficiente para o pagamento do serviço Plano Pago, serão feitas outras três tentativas nos três dias subsequentes à cobrança inicial, sendo que, após o terceiro dia de inadimplemento, o serviço será cancelado pela Cora.',
            },
            {
              item:
                'Você se compromete a não utilizar os serviços do Plano Pago da Cora para a realização de pagamentos, transferências ou cobranças relacionadas: (I) a atividades ilegais, tais como, lavagem de dinheiro, estelionato, realização de fraudes contra terceiros, jogos de azar e apostas em geral; (II) à compra e venda de produtos e serviços considerados ilegais pela legislação brasileira; (III) à venda de produtos ou serviços identificados pelos órgãos governamentais como tendo uma alta probabilidade de serem fraudulentos ou que representem risco à segurança física e financeira do consumidor; (IV) a produtos que incentivem, promovam, facilitem ou ensinem pessoas a praticarem atividades ilegais; e (V) à promoção de ódio, violência, racismo ou outras formas de intolerância discriminatória.',
            },
          ],
        },
      ],
    },
    {
      title: 'Informações Adicionais',
      list: [
        {
          head: 'Informações Adicionais',
          items: [
            {
              item:
                'Limitação de responsabilidade. Sua Empresa reconhece que todo sistema que depende de rede mundial de computadores está sujeito a falhas e indisponibilidade que, portanto, não acarretarão na responsabilização da CORA e/ou das Parceiras.',
            },
            {
              item:
                'Cookies. Ao acessar ou navegar pelas plataformas web da CORA você concorda e estará sujeito ao uso de Cookies de empresas terceiras, conforme a Política de Privacidade.',
            },
            {
              item:
                'Cessão. A CORA poderá, a qualquer momento e a seu critério, ceder quaisquer de seus direitos e obrigações previstos nestes Termos de Uso a qualquer pessoa, mediante simples notificação prévia a Sua Empresa, ficando desde já ressalvado que a cessionária continuará a cumprir com todas as obrigações assumidas pela CORA.',
            },
            {
              item:
                'Alterações nos Termos de Uso. Estes Termos de Uso podem ser alterados a qualquer tempo e, sempre que ocorrer qualquer modificação, Sua Empresa será informada previamente. Sua Empresa demonstrará que concorda com todas as modificações se continuar a utilizar os Serviços após a alteração dos Termos de Uso. Caso não concorde com os novos Termos de Uso, Sua Empresa poderá rejeitá-los, realizando o cancelamento dos Serviços.',
            },
            {
              item:
                'Prazo de Vigência. Estes Termos de Uso e a Política de Privacidade vigerão por prazo indeterminado, a partir de sua aceitação por Sua Empresa.',
            },
            {
              item:
                'Lei Aplicável. Estes Termos de Uso e a Política de Privacidade são regidos pelas leis da República Federativa do Brasil.',
            },
            {
              item:
                'Dados. A Empresa Selecionada desde já autoriza a CORA a coletar, tratar e compartilhar os seus dados cadastrais, financeiros e pessoais com as instituições financeiras, pertencentes ou não ao grupo da CORA, para obtenção dos financiamentos. A CORA declara que segue os ditames da Lei nº 13.709, de 14 de agosto de 2018 (Lei Geral de Proteção de Dados Pessoais) no tratamento dos dados do Usuário.',
            },
          ],
        },
      ],
    },
  ],
}
